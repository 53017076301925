import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import {toast} from "react-toastify";
import {RequestPostCheckout} from "../store/checkout/checkoutSlice";
import {RequestPostGuestOrder, RequestPostOrder,} from "../store/order/orderSlice";
import {RequestPostGuestPayment, RequestPostPayment,} from "../store/payment/paymentSlice";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import {setOpenLink, setPaymentLoading, setPaymentUrl,} from "../store/globalSlice";
import { RequestGetGuestCart } from "../store/guestCart/guestCart";
import { RequestGetCart } from "../store/cart/cartSlice";
import ReactPixel from 'react-facebook-pixel';

function useCheckout() {
    const dispatch = useDispatch<AppDispatch>();

    // token
    const user = useSelector((state: RootState) => state.user.user);
    const guestUser = useSelector((state: RootState) => state.guestCart.cartID);

    // items
    const items = useSelector((state: RootState) => state.cart.items);
    const itemsGuest = useSelector((state: RootState) => state.guestCart.items);

    const type = useSelector((state: RootState) => state.orders.payment_method);
    // Initialize Facebook Pixel
ReactPixel.init('346743781561123'); // Replace with your actual Pixel ID
const fbq = ReactPixel.fbq;

    // Navigate
    const navigate = useNavigate();

    const handleCheckout = (cartID: any, values: any) => {
console.log(values);
if (typeof fbq === 'function') {
    fbq('track', 'InitiateCheckout', {

      content_ids: [cartID],
      value: values.total, // Assuming `values` contains a `total` property
      currency: 'EGP',
    });
  }
   
        // credit User
        const creditCardUser = (cartID: any, values: any) => {

            // dispatch(setPaymentLoading(true));
            // dispatch(RequestPostCheckout(values)).then((result: any) => {
            //   if (result.type === "RequestPostCheckout/fulfilled") {
            //
            //     ).then((result) => {
            //       if (result.type == "RequestPostOrder/fulfilled") {
            //         dispatch(RequestPostPayment(result.payload.id)).then((result) => {
            //           dispatch(setOpenLink(true));
            //           dispatch(setPaymentUrl(result?.payload?.redirect_url));
            //           toast.success(t("order_done_credit"));
            //         });
            //       }
            //     });
            //   }
            // });

            dispatch(setPaymentLoading(true));
            dispatch(
                RequestPostOrder({
                    ...values,
                })
            ).then((result) => {
                dispatch(RequestPostPayment(result?.payload?.id)).then(
                    (result) => {
                        dispatch(setOpenLink(true));
                        dispatch(setPaymentUrl(result?.payload?.redirect_url));
                        toast.success(t("order_done_credit"));
                        dispatch(RequestGetCart())
                    }
                );
            });
        };

        const creditGuest = (cartID: any, values: any) => {
            dispatch(setPaymentLoading(true));
            dispatch(
                RequestPostGuestOrder({
                    cart_id: guestUser,
                    shipping_address: `${values.address} _ ${values.apartment}`,
                    ...values,
                })
            ).then((result) => {
                dispatch(RequestPostGuestPayment(result?.payload?.id)).then(
                    (result) => {
                        dispatch(setOpenLink(true));
                        dispatch(setPaymentUrl(result?.payload?.redirect_url));
                        toast.success(t("order_done_credit"));
                        dispatch(RequestGetGuestCart())
                    }
                );
            });
        };

        // cash User
        const cashDeliveryUser = (cartID: any, values: any) => {
            // dispatch(RequestPostCheckout(values)).then((result: any) => {
            //     if (result.type === "RequestPostCheckout/fulfilled") {
            //
            //     }
            // });
            dispatch(
                RequestPostOrder({
                    cart_id: cartID,
                    shipping_address: `${values.address} _ ${values.apartment}`,
                    ...values,
                    order_info: "Delivery", // remove it
                    phone_number: "null", // remove it
                    order_status: "credit card", // EDIT
                })
            ).then((result) => {
                if (result.type === "RequestPostOrder/fulfilled") {
                    toast.success(t("order_done"));
                    dispatch(RequestGetCart());
                    navigate("/");
                }
            });

        };
        // cash delivery guest
        const cashDeliveryGuest = (cartID: any, values: any) => {
            dispatch(
                RequestPostGuestOrder({
                    cart_id: guestUser,
                    shipping_address: `${values.address} _ ${values.apartment}`,
                    ...values,
                    order_info: "Delivery", // remove it
                    phone_number: "null", // remove it
                    order_status: "credit card", // EDIT
                })
            )
                .then((result) => {
                    toast.success(t("order_done"));
                    dispatch(RequestGetGuestCart());
                    navigate("/");
                })
                .catch((error) => {
                });
        };

        // credit guest





        // if user
        if (user) {
            if (items.length === 0) {
                toast.error(t("no_items"));
                return false;
            }
            if (type === "Cash") {
                cashDeliveryUser(cartID, values);
            } else {
                creditCardUser(cartID, values);
            }
        } else {
            if (itemsGuest.length === 0) {
                toast.error(t("no_items"));
                return false;
            }
            if (type === "Cash") {
                cashDeliveryGuest(cartID, values);
            } else {
                creditGuest(cartID, values);
            }
        }
    };

    return handleCheckout;
}

export default useCheckout;
