import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { RequestPostToCart } from "../store/cart/cartSlice";
import {
  RequestPostToGuestCart,
} from "../store/guestCart/guestCart";
import ReactPixel from 'react-facebook-pixel';

function useAddToCart() {
  const dispatch = useDispatch<AppDispatch>();

  // token
  const user = useSelector((state: RootState) => state.user.user);
  const guestUser = useSelector((state: RootState) => state.guestCart.cartID);

// Initialize Facebook Pixel
ReactPixel.init('346743781561123'); // Replace with your actual Pixel ID
const fbq = ReactPixel.fbq;

  
//   quantity: any, product_id: any
  const handleAddItem = (quantity: any, product_id: any,color:any,image:any) => {
    if (typeof fbq === 'function') {
      fbq('track', 'AddToCart', {
        content_name: product_id,
        content_ids: [product_id],
        value: quantity, // Adjust the value as necessary
        currency: 'EGP',
      });
    }
    // setOpenDrawer(true);
    // dispatch(setOpenDrawerCart(true))
    if (user) {
      dispatch(RequestPostToCart({ quantity: quantity, product_id: product_id ,color_hex:color,image:image}));
    } 
    else {
      dispatch(
        RequestPostToGuestCart({ quantity: quantity, product_id: product_id ,color_hex:color,image:image})
      );
    } 
    // if (guestUser !== null)
    // else {
    //   dispatch(()).then((result) => { 
    //     dispatch(
    //       RequestPostToGuestCart({ quantity: quantity, product_id: product_id })
    //     );
    //   });
    // }
  };

  return handleAddItem;
}

export default useAddToCart;
